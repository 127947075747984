
import {Component, Vue} from 'vue-property-decorator';
import {IAlertCreateChannel} from '@/interfaces';
import {dispatchCreateAlertChannel} from '@/store/admin/actions';

@Component
export default class CreateAlertChannel extends Vue {
  public valid: boolean = true;
  public symbol?: string = '';
  public exchange?: string = '';
  public initialValue?: number = 0;
  public limitUpper?: number = 0;
  public limitLower?: number = 0;
  public note?: string = '';

  public async mounted() {
    this.reset();
  }

  public reset() {
    this.symbol = undefined;
    this.exchange = undefined;
    this.initialValue = undefined;
    this.limitUpper = undefined;
    this.limitLower = undefined;
    this.note = undefined;
    this.$validator.reset();
  }

  public upCaseSymbol() {
    if (this.symbol) {
      this.symbol = this.symbol.toUpperCase();
    }
  }

  public upCaseExchange() {
    if (this.exchange) {
      this.exchange = this.exchange.toUpperCase();
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const createdAlert: IAlertCreateChannel = {owner_id: this.userId};

      createdAlert.symbol = this.symbol;
      createdAlert.exchange = this.exchange;
      createdAlert.initial_value = this.initialValue;
      createdAlert.limit_upper = this.limitUpper;
      createdAlert.limit_lower = this.limitLower;
      createdAlert.note = this.note;

      await dispatchCreateAlertChannel(this.$store, createdAlert);
      this.$router.push(`/main/admin/users/${this.userId}/alerts`);
    }
  }

  get userId(): number {
    return +this.$router.currentRoute.params.user_id;
  }
}
