var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Create Alert")])]),_c('v-card-text',[[_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"subheading secondary--text text--lighten-2"},[_vm._v("Title")]),_c('div',{staticClass:"title primary--text text--darken-2"},[_vm._v("Price - channel value")])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-text-field',{attrs:{"label":"Symbol","rules":[
                            v => !!v || 'Symbol is required',
                            v => (v && v.length <= 10) || 'Symbol must be up to ten characters']},on:{"keyup":_vm.upCaseSymbol},model:{value:(_vm.symbol),callback:function ($$v) {_vm.symbol=$$v},expression:"symbol"}}),_c('v-text-field',{attrs:{"label":"Exchange","rules":[
                            v => !!v || 'Exchange is required',
                            v => (v && v.length <= 6) || 'Exchange symbol must be up to six characters']},on:{"keyup":_vm.upCaseExchange},model:{value:(_vm.exchange),callback:function ($$v) {_vm.exchange=$$v},expression:"exchange"}}),_c('v-text-field',{attrs:{"label":"Initial Value","type":"number","rules":[
                            v => !!v || 'Initial value is required',
                            v => +v > 0 || 'Initial value must be a positive number',
                            v => +v < 1000000 || 'Initial value must be less than 1,000,000']},model:{value:(_vm.initialValue),callback:function ($$v) {_vm.initialValue=$$v},expression:"initialValue"}}),_c('v-text-field',{attrs:{"label":"Lower limit","type":"number","rules":[
                            v => !!v || 'Lower limit is required',
                            v => +v > 0 || 'Lower limit must be a positive number',
                            v => +v < _vm.initialValue || 'Lower limit must be less than initial value']},model:{value:(_vm.limitLower),callback:function ($$v) {_vm.limitLower=$$v},expression:"limitLower"}}),_c('v-text-field',{attrs:{"label":"Upper limit","type":"number","rules":[
                            v => !!v || 'Upper limit is required',
                            v => +v > 0 || 'Upper limit must be a positive number',
                            v => +v > _vm.initialValue || 'Upper limit should be greater than initial value',
                            v => +v < 1000000 || 'Upper limit must be less than 1,000,000']},model:{value:(_vm.limitUpper),callback:function ($$v) {_vm.limitUpper=$$v},expression:"limitUpper"}},[_vm._v(" > ")]),_c('v-text-field',{attrs:{"label":"Note","maxlength":"200"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)]],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{on:{"click":_vm.reset}},[_vm._v("Reset")]),_c('v-btn',{attrs:{"disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }