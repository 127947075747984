
import {Component, Vue} from 'vue-property-decorator';
import {dispatchGetAlerts} from '@/store/admin/actions';
import {IAlert} from '@/interfaces';

@Component
export default class Alerts extends Vue {
  public alerts?: IAlert[] = [];

  public alertMenu = [
    {title: 'Crossing value', path: 'main-admin-alerts-create-crossing'},
    {title: 'Channel value', path: 'main-admin-alerts-create-channel'},
    {title: 'Delta change by value', path: 'main-admin-alerts-create-delta'},
    {title: 'Delta change by percent', path: 'main-admin-alerts-create-delta-percent'},
  ];

  public headers = [
    {text: 'Symbol', sortable: true, value: 'symbol', align: 'left'},
    {text: 'Alert On', sortable: true, value: 'variable', align: 'left'},
    {text: 'Initial Value', sortable: true, value: 'initial_value', align: 'right'},
    {text: 'Reaches', sortable: false, value: 'limit_lower', align: 'left'},
    {text: 'Active', sortable: false, value: 'is_active', align: 'left'},
    {text: 'Actions', value: 'id', align: 'center', sortable: false},
  ];

  get userId(): number {
    return +this.$router.currentRoute.params.id;
  }

  public async mounted() {
    this.alerts = await dispatchGetAlerts(this.$store, this.userId);
  }
}
