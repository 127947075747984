
import {Component, Vue} from 'vue-property-decorator';
import {IJournal} from '@/interfaces';
import {dispatchGetJournalEntry, dispatchUpdateJournalEntry} from '@/store/admin/actions';

@Component
export default class EditJournal extends Vue {
  public entry?: IJournal = {} as IJournal;
  public EXCHANGES = ['NASDAQ', 'NYSE', 'EURONEXT', 'INDEX', 'OSE'];

  public valid: boolean = true;
  public symbol: string = '';
  public exchange: string = '';
  public transactionType: string = '';
  public entryDate: string = '';
  public size?: number = 0;
  public entryPrice?: number = 0;
  public exitPrice?: number | null = null;
  public stopLoss?: number | null = null;
  public takeProfit?: number | null = null;
  public profit?: number | null = null;


  public async mounted() {
    this.entry = await dispatchGetJournalEntry(this.$store, this.entryId);
    this.reset();
  }

  public reset() {
    this.symbol = '';
    this.exchange = '';
    this.transactionType = '';
    this.entryDate = '';
    this.size = undefined;
    this.entryPrice = undefined;
    this.exitPrice = null;
    this.stopLoss = null;
    this.takeProfit = null;
    this.profit = null;

    this.$validator.reset();
    if (this.entry) {
      this.symbol = this.entry.symbol;
      this.exchange = this.entry.exchange;
      this.transactionType = this.entry.transaction_type;
      this.entryDate = new Date(this.entry.entry_date).toISOString().substr(0, 10);
      this.size = this.entry.size;
      this.entryPrice = this.entry.entry_price;
      this.exitPrice = this.entry.exit_price;
      this.stopLoss = this.entry.stop_loss;
      this.takeProfit = this.entry.take_profit;
      this.profit = this.entry.profit;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedEntry: IJournal = {
        symbol: this.symbol,
        exchange: this.exchange,
        transaction_type: this.transactionType,
        size: this.size!,
        entry_date: new Date(this.entryDate),
        entry_price: this.entryPrice!,
      };

      updatedEntry.exit_price = this.exitPrice ? this.exitPrice : null;
      updatedEntry.stop_loss = this.stopLoss ? this.stopLoss : null;
      updatedEntry.take_profit = this.takeProfit ? this.takeProfit : null;
      updatedEntry.profit = this.profit ? this.profit : null;

      await dispatchUpdateJournalEntry(this.$store, {id: this.entry!.id!, entry: updatedEntry});
      this.$router.push(`/main/admin/users/${this.userId}/journal`);
    }
  }

  public upCaseSymbol() {
    if (this.symbol) {
      this.symbol = this.symbol.toUpperCase();
    }
  }

  get userId(): number {
    return +this.$router.currentRoute.params.user_id;
  }

  get entryId(): number {
    return +this.$router.currentRoute.params.id;
  }
}
