
import {Component, Vue} from 'vue-property-decorator';
import {IJournal} from '@/interfaces';
import {dispatchCreateJournalEntry} from '@/store/admin/actions';

@Component
export default class CreateEntry extends Vue {
  public EXCHANGES = ['NASDAQ', 'NYSE', 'EURONEXT', 'INDEX', 'OSE'];

  public valid: boolean = true;
  public symbol?: string = '';
  public exchange: string = '';
  public transactionType: string = '';
  public entryDate: string = '';
  public size?: number = 0;
  public entryPrice?: number = 0;
  public exitPrice?: number = 0;
  public stopLoss?: number = 0;
  public takeProfit?: number = 0;
  public profit?: number = 0;

  public upCaseSymbol() {
    if (this.symbol) {
      this.symbol = this.symbol.toUpperCase();
    }
  }

  get userId(): number {
    return +this.$router.currentRoute.params.user_id;
  }

  public async mounted() {
    this.reset();
  }

  public reset() {
    this.symbol = undefined;
    this.exchange = this.EXCHANGES[0];
    this.transactionType = 'buy';
    this.entryDate = new Date(Date.now()).toISOString().substr(0, 10);
    this.size = undefined;
    this.entryPrice = undefined;
    this.exitPrice = undefined;
    this.stopLoss = undefined;
    this.takeProfit = undefined;
    this.profit = undefined;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const createdEntry: IJournal = {
        owner_id: this.userId,
        symbol: this.symbol!,
        exchange: this.exchange,
        transaction_type: this.transactionType,
        size: this.size!,
        entry_date: new Date(this.entryDate),
        entry_price: this.entryPrice!,
      };

      if (this.exitPrice) {
        createdEntry.exit_price = this.exitPrice;
      }
      if (this.stopLoss) {
        createdEntry.stop_loss = this.stopLoss;
      }
      if (this.takeProfit) {
        createdEntry.take_profit = this.takeProfit;
      }
      if (this.profit) {
        createdEntry.profit = this.profit;
      }

      await dispatchCreateJournalEntry(this.$store, createdEntry);
      this.$router.push(`/main/admin/users/${this.userId}/journal`);
    }
  }
}
