
import {Component, Vue} from 'vue-property-decorator';
import {IAlert, IAlertUpdate} from '@/interfaces';
import {dispatchGetAlert, dispatchUpdateAlert} from '@/store/admin/actions';

@Component
export default class EditAlert extends Vue {
  public alert?: IAlert = {} as IAlert;
  public valid = true;
  public initialValue?: number;
  public limitLower?: number;
  public limitUpper?: number;
  public delta?: number;
  public isActive: boolean = true;
  public isRecurring: boolean = false;
  public direction: string = 'up';
  public alertType: string = '';
  public note: string = '';
  public hasPushNotification: boolean = true;
  public hasEmailNotification: boolean = false;
  public hasTextNotification: boolean = false;


  public async mounted() {
    this.alert = await dispatchGetAlert(this.$store, this.alertId);
    this.reset();
  }

  public reset() {
    this.limitLower = undefined;
    this.limitUpper = undefined;
    this.delta = undefined;
    this.isActive = true;
    this.isRecurring = false;
    this.direction = 'both';
    this.initialValue = undefined;
    this.alertType = '';
    this.note = '';
    this.hasPushNotification = true;
    this.hasEmailNotification = false;
    this.hasTextNotification = false;

    this.$validator.reset();
    if (this.alert) {
      this.limitLower = this.alert.limit_lower;
      this.limitUpper = this.alert.limit_upper;
      this.delta = this.alert.delta;
      this.isActive = this.alert.is_active;
      this.isRecurring = this.alert.is_recurring;
      this.direction = this.alert.direction;
      this.initialValue = this.alert.initial_value;
      this.alertType = this.alert.alarm_type;
      this.note = this.alert.note;
      this.hasPushNotification = this.alert.has_push_notification;
      this.hasEmailNotification = this.alert.has_email_notification;
      this.hasTextNotification = this.alert.has_text_notification;    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedAlert: IAlertUpdate = {};

      if (this.delta) {
        updatedAlert.delta = this.delta;
        updatedAlert.direction = this.direction;
      } else {
        if (this.limitUpper) {
          updatedAlert.limit_upper = this.limitUpper;
        }
        if (this.limitLower) {
          updatedAlert.limit_lower = this.limitLower;
        }
      }

      updatedAlert.is_active = this.isActive;
      updatedAlert.is_recurring = this.isRecurring;
      updatedAlert.has_push_notification = this.hasPushNotification;
      updatedAlert.has_email_notification = this.hasEmailNotification;
      updatedAlert.has_text_notification = this.hasTextNotification;
      updatedAlert.note = this.note;

      await dispatchUpdateAlert(this.$store, {id: this.alert!.id, alert: updatedAlert});
      this.$router.push(`/main/admin/users/${this.userId}/alerts`);
    }
  }

  get userId(): number {
    return +this.$router.currentRoute.params.user_id;
  }

  get alertId(): number {
    return +this.$router.currentRoute.params.id;
  }
}
