var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Edit Alert")])]),_c('v-card-text',[[_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"subheading secondary--text text--lighten-2"},[_vm._v("Title")]),(_vm.alert)?_c('div',{staticClass:"title primary--text text--darken-2"},[_vm._v(_vm._s(_vm.alert.title))]):_c('div',{staticClass:"title primary--text text--darken-2"},[_vm._v("-----")])]),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"caption secondary--text"},[_vm._v("Stock")]),(_vm.alert)?_c('span',[_vm._v(_vm._s(_vm.alert.symbol)+" : "+_vm._s(_vm.alert.exchange))]):_vm._e()]),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"caption secondary--text"},[_vm._v("Initial Value")]),(_vm.alert)?_c('span',[_vm._v(_vm._s(_vm.alert.initial_value))]):_vm._e()]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.alertType !== 'channel_value' && _vm.alertType !== 'crossing_value')?_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.direction),callback:function ($$v) {_vm.direction=$$v},expression:"direction"}},[_c('div',{staticClass:"caption secondary--text text--lighten-2 mr-2"},[_vm._v("Direction")]),_c('v-radio',{attrs:{"value":"up","label":"Up"}}),_c('v-radio',{attrs:{"value":"down","label":"Down"}}),_c('v-radio',{attrs:{"value":"both","label":"Both"}})],1):_vm._e(),(_vm.alertType === 'channel_value' || (_vm.alertType === 'crossing_value' && _vm.direction === 'down'))?_c('v-text-field',{attrs:{"label":"Lower limit","type":"number","rules":[
                            v => !!v || 'Lower limit is required',
                            v => +v > 0 || 'Lower limit must be a positive number',
                            v => v < _vm.initialValue || 'Lower limit must be less than initial value']},model:{value:(_vm.limitLower),callback:function ($$v) {_vm.limitLower=$$v},expression:"limitLower"}}):_vm._e(),(_vm.alertType === 'channel_value' || (_vm.alertType === 'crossing_value' && _vm.direction === 'up'))?_c('v-text-field',{attrs:{"label":"Upper limit","type":"number","rules":[
                            v => !!v || 'Upper limit is required',
                            v => +v > 0 || 'Upper limit must be a positive number',
                            v => v > _vm.initialValue || 'Upper limit should be greater than initial value',
                            v => v < 1000000 || 'Upper limit must be less than 1,000,000']},model:{value:(_vm.limitUpper),callback:function ($$v) {_vm.limitUpper=$$v},expression:"limitUpper"}}):_vm._e(),(_vm.alertType === 'delta_percent')?_c('v-text-field',{attrs:{"label":"Delta (percent)","suffix":"%","type":"number","rules":[
                            v => !!v || 'Delta value is required',
                            v => +v > 0 || 'Delta value must be a positive number',
                            v => +v < 100 || 'Delta value must be less than 100']},model:{value:(_vm.delta),callback:function ($$v) {_vm.delta=$$v},expression:"delta"}}):_vm._e(),(_vm.alertType === 'delta_value')?_c('v-text-field',{attrs:{"label":"Delta","type":"number","rules":[
                            v => !!v || 'Delta value is required',
                            v => +v > 0 || 'Delta value must be a positive number',
                            v => v < 1000000 || 'Delta value must be less than 1,000,000']},model:{value:(_vm.delta),callback:function ($$v) {_vm.delta=$$v},expression:"delta"}}):_vm._e(),_c('v-checkbox',{attrs:{"label":"Is Recurring"},model:{value:(_vm.isRecurring),callback:function ($$v) {_vm.isRecurring=$$v},expression:"isRecurring"}}),_c('v-checkbox',{attrs:{"label":"Is Active"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}}),_c('v-text-field',{attrs:{"label":"Note","maxlength":"200"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('p',{staticClass:"caption"},[_vm._v("Notification Preferences")]),_c('v-checkbox',{attrs:{"label":"Push Notification"},model:{value:(_vm.hasPushNotification),callback:function ($$v) {_vm.hasPushNotification=$$v},expression:"hasPushNotification"}}),_c('v-checkbox',{attrs:{"label":"Email"},model:{value:(_vm.hasEmailNotification),callback:function ($$v) {_vm.hasEmailNotification=$$v},expression:"hasEmailNotification"}}),_c('v-checkbox',{attrs:{"label":"Text Message"},model:{value:(_vm.hasTextNotification),callback:function ($$v) {_vm.hasTextNotification=$$v},expression:"hasTextNotification"}})],1)]],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{on:{"click":_vm.reset}},[_vm._v("Reset")]),_c('v-btn',{attrs:{"disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }