
import {Component, Vue} from 'vue-property-decorator';
import {dispatchGetJournalEntries} from '@/store/admin/actions';
import {IJournal} from '@/interfaces';

@Component
export default class Alerts extends Vue {
  public entries?: IJournal[] = [];
  public sortBy: string = 'entry_date';

  public pagination = {
    sortBy: 'entry_date',
    descending: true,
  };

  public headers = [
    {text: 'Symbol', sortable: true, value: 'symbol', align: 'left'},
    {text: 'Type', sortable: true, value: 'transaction_type', align: 'left'},
    {text: 'Size', sortable: true, value: 'size', align: 'right'},
    {text: 'Entry Date', sortable: true, value: 'entry_date', align: 'left'},
    {text: 'Entry Price', sortable: false, value: 'entry_price', align: 'right'},
    {text: 'Stop Loss', sortable: false, value: 'stop_loss', align: 'right'},
    {text: 'Take Profit', sortable: false, value: 'take_profit', align: 'right'},
    {text: 'Profit', sortable: true, value: 'profit', align: 'right'},
    {text: 'Actions', value: 'id', align: 'center', sortable: false},
  ];

  get userId(): number {
    return +this.$router.currentRoute.params.id;
  }

  public async mounted() {
    this.entries = await dispatchGetJournalEntries(this.$store, this.userId);
  }
}
